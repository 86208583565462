import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { for: "rate" }
const _hoisted_3 = ["readonly"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString($props.label), 1 /* TEXT */),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      readonly: $props.read_only,
      class: "form-control",
      id: "rate",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.rate) = $event))
    }, null, 8 /* PROPS */, _hoisted_3), [
      [_vModelText, $data.rate]
    ])
  ]))
}