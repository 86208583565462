import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "inner-form-group" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["value", "disabled"]
const _hoisted_5 = {
  key: 0,
  class: "text-muted"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString($props.label), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("select", {
        disabled: $props.read_only,
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.value) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            value: option.value,
            disabled: option.disabled
          }, _toDisplayString(option.label || option.value), 9 /* TEXT, PROPS */, _hoisted_4))
        }), 256 /* UNKEYED_FRAGMENT */))
      ], 8 /* PROPS */, _hoisted_3), [
        [_vModelSelect, $data.value]
      ]),
      ($props.help_text)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($props.help_text), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}