import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-wrapper" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_cost_estimation_form = _resolveComponent("cost-estimation-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_cost_estimation_form, {
      frm: $props.frm,
      document: $data.document,
      ref: "calculator",
      readonly: $options.readonly
    }, null, 8 /* PROPS */, ["frm", "document", "readonly"])
  ]))
}