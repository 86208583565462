import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "readonly"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", { for: $data.id }, _toDisplayString($props.label), 9 /* TEXT, PROPS */, _hoisted_2),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "form-control",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.value) = $event)),
      onChange: _cache[1] || (_cache[1] = (...args) => ($options.on_change && $options.on_change(...args))),
      id: $data.id,
      readonly: $props.read_only
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3), [
      [_vModelText, $data.value]
    ])
  ]))
}