import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_select_field = _resolveComponent("select-field")

  return (_openBlock(), _createBlock(_component_select_field, {
    label: $props.label,
    options: $data.options,
    onAfter_select: $options.after_select,
    selected: $data.value,
    read_only: $props.read_only
  }, null, 8 /* PROPS */, ["label", "options", "onAfter_select", "selected", "read_only"]))
}